const BASEURL_WEB = 'https://api.addcell.org';
const BASEURL_LOCAL = 'http://www.addcell.test:51539';
const axios = require('axios').default;
import Cookies from '../cookies';

function GetUrl(controller){
  if (window.location.host=='dashboard.addcell.org')
    return BASEURL_WEB + (controller[0]=='/'?'':'/') + controller;
  else
    return BASEURL_LOCAL + (controller[0]=='/'?'':'/') + controller;
}

function GetAxiosConfig(){
  var t= Cookies.getCookie("t");
  if (t==null || t.length<10)
    return {};
  return {
      headers: {
        AUTHTOKEN: t
      }
  };
}

function Post(controller,data){
  return axios.post(GetUrl(controller),data,GetAxiosConfig());
}
function Get(controller){
  return axios.get(GetUrl(controller),GetAxiosConfig());
}

export default {Post,Get};